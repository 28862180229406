<template>
    <b-field>
        <b-select
            v-bind="$attrs"
            :value="value"
            expanded
            @change="$emit('change')"
            @input="onInput">
            <slot></slot>
        </b-select>
    </b-field>
</template>

<script>
  export default {
    name: "CustomSelect",
    props: {
      value: {
        type: [String, Number],
        required: true
      }
    },
    methods: {
      onInput (value) {
        this.$emit("input", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .select-icon {
        top: 0;
        bottom: 0;
        right: 16px;
        margin: auto;
        color: #9d9d9d;
        position: absolute;
        pointer-events: none;
    }
</style>
